export function start() {

  const body = $('body')
  const carousels = $('[data-carousel]')

  carousels.each(initCarousel)

  function initCarousel(index) {
    const carousel = carousels.eq(index),
          items = $(carousel).find('[data-carousel-item]'),
          total = items.length
    let current = 0

    items.eq(0).addClass('active')

    carousel.find('[data-next]').on('click', showNextItem)
    carousel.find('[data-prev]').on('click', showPrevItem)

    function showNextItem(event) {
      event.preventDefault()
      const next = current
      current = current + 1
      setItem(next, current)
    }

    function showPrevItem(event) {
      event.preventDefault()
      const prev = current
      current = current - 1
      setItem(prev, current)
    }

    function setItem(prev, next) {
      let slide = current

      if (next > total - 1) {
        slide = 0
        current = 0
      }
      if (next < 0) {
        slide = total - 1
        current = total - 1
      }

      items.eq(prev).removeClass('active')
      items.eq(slide).addClass('active')
    }
  }

}
