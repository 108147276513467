export function start() {
  const body = $('body'),
        nav = $('#nav'),
        links = nav.find('a'),
        navHeight = nav.outerHeight()

  // hash of { anchor: offset } for scroll handling
  const panelsOffsets = {}

  // init currentPanel
  let currentPanel = links.get(0).hash

  // nav link click handler
  nav.on('click', 'a', event => {
    event.preventDefault()

    const targetPanel = event.currentTarget.hash
    currentPanel = getDocumentHash()
    changePanel(targetPanel)
  })

  // scroll handler
  body.on('scroll', function(e) {
    e.preventDefault()
    const panel = detectPanel()
    if (panel && panel != currentPanel) {
      // console.log(`CHANGE NAV LINK FROM ${currentPanel} TO ${panel}`);
      currentPanel = panel
      setDocumentHash(panel)
      setCurrentLink(panel)
    }
  })

  $(window).on('resize', calcPanelsOffsets)

  calcPanelsOffsets()
  initNav()

  function initNav() {
    const hash = getDocumentHash()
    if (hash.length)
      try {
        changePanel(hash)
        currentPanel = hash
      } catch (e) {
        // Most likely the hash doesn't relate to a panel
      }
  }

  function calcPanelsOffsets() {
    links.each((i, link) => {
      const hash = link.hash,
            el = $(hash),
            offset = el.offset()

      panelsOffsets[hash] = {
        top: offset.top,
        bottom: offset.top + el.outerHeight()
      }
    })
  }

  function changePanel(panelId) {
    scrollToPanel(panelId)
    setCurrentLink(panelId)
    setDocumentHash(panelId)
  }

  function scrollToPanel(panelId) {
    const panel = $(panelId)
    let offset = panel.offset().top - body.offset().top + body.scrollTop() - navHeight
    body.animate({ scrollTop: offset }, 750)
  }

  function setCurrentLink(panelId) {
    links.removeClass('current')
    nav.find(`[href="${panelId}"]`).addClass('current')
  }

  function getDocumentHash() {
    return document.location.hash
  }

  function setDocumentHash(hash) {
    if (history.pushState) {
      history.pushState(null, null, hash);
    }
    else {
      location.hash = hash;
    }
  }

  function detectPanel() {
    const scrollOffset = body.offset().top + body.scrollTop() + navHeight

    for (const [panel, props] of Object.entries(panelsOffsets)) {
      // console.log(`${scrollOffset} -> [${props.top}, ${props.bottom}] (${panel})`);
      if (props.top <= scrollOffset && scrollOffset < props.bottom) {
        return panel
      }
    }
  }
}
