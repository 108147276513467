export function start() {
  const body = $('body'),
        images = body.find('header:first .image'),
        imagesCount = images.length

  let currentImageIndex = 0

  setInterval(changeImage, 5000)

  function changeImage() {
    const currentImage = $(images.get(currentImageIndex))
    currentImageIndex = (currentImageIndex + 1) % imagesCount
    const nextImage = $(images.get(currentImageIndex))

    currentImage
      .removeClass('current')
      .addClass('going')
    nextImage
      .removeClass('gone')
      .addClass('current')

    setTimeout(() => {
      currentImage
        .removeClass('going')
        .addClass('gone')
    }, 1000)
  }
}
